import React from "react"
import { createUseStyles } from "react-jss"

import Layout, { LinkAction } from "../components/Layout"
import fallbackImg from "../../data/photoImages/attic_bedroom.jpg"

const useStyles = createUseStyles(theme => ({
  root: {
    paddingTop: "1.2rem",
    width: "100vw",
    display: "flex",
    flexDirection: "column",
  },
  title: {
    textAlign: "center",
    marginBottom: "1rem",
  },
  video: {
    width: "100%",
  },
}))

const links: Array<LinkAction> = [{ txt: "photos", to: "/Photos" }]
const Video = () => {
  const c = useStyles()
  return (
    <Layout links={links}>
      <div className={c.root}>
        <h1 className={c.title}>Take a little time off and enjoy the video!</h1>
        <video
          className={c.video}
          autoPlay="autoplay"
          loop
          src="https://staticvvernazza.blob.core.windows.net/static/villa-vernazza.mp4"
          poster={fallbackImg}
        ></video>
      </div>
    </Layout>
  )
}

export default Video
